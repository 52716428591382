var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.routeMetaTitle
          },
          on: {
            "go-back": _vm.goBack
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "page"
        }, [_c('van-cell-group', {
          staticClass: "cell-group"
        }, [_c('van-cell', {
          attrs: {
            "title": "调入申请员",
            "value": _vm.inApplicant
          }
        })], 1), _c('div', {
          staticClass: "list"
        }, [_vm._l(_vm.productList, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "item"
          }, [_c('div', {
            staticClass: "title"
          }, [_c('span', [_vm._v("申请明细：第" + _vm._s(index + 1) + "条")]), _c('van-icon', {
            attrs: {
              "name": "delete-o"
            },
            on: {
              "click": function ($event) {
                return _vm.onDelItem(index);
              }
            }
          })], 1), _c('van-cell-group', {
            staticClass: "cell-group"
          }, [_c('van-cell', {
            attrs: {
              "title": "资产属性",
              "required": "",
              "is-link": "",
              "value": _vm.productPropertyMap[item.productProperty] || '请选择'
            },
            on: {
              "click": function ($event) {
                return _vm.onProductPropertyShow(index);
              }
            }
          }), _c('van-cell', {
            attrs: {
              "title": "资产名称",
              "required": "",
              "is-link": "",
              "value": item.productName || '请选择'
            },
            on: {
              "click": function ($event) {
                return _vm.onProductListShow(index);
              }
            }
          }), _c('van-cell', {
            attrs: {
              "title": "主规格",
              "value": item.standard === null ? '根据选择的资产自动带入' : item.standard || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "单位",
              "value": item.unit === null ? '根据资产名称自动填充' : item.unit || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "库存数量",
              "value": item.count === null ? '根据资产名称自动填充' : item.count || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "申请数量",
              "required": ""
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function () {
                return [_c('van-stepper', {
                  attrs: {
                    "integer": "",
                    "max": item.productProperty === 3 ? 1 : item.count
                  },
                  model: {
                    value: item.applicationNum,
                    callback: function ($$v) {
                      _vm.$set(item, "applicationNum", $$v);
                    },
                    expression: "item.applicationNum"
                  }
                })];
              },
              proxy: true
            }], null, true)
          })], 1)], 1);
        }), _c('div', {
          staticClass: "addBtn"
        }, [_c('van-button', {
          attrs: {
            "block": "",
            "plain": "",
            "type": "primary",
            "icon": "add-o"
          },
          on: {
            "click": _vm.onAddItem
          }
        }, [_vm._v("添加物资")])], 1)], 2), _c('van-cell-group', {
          staticClass: "cell-group"
        }, [_c('van-cell', {
          attrs: {
            "title": "调出项目",
            "value": _vm.outProjectName || '由第一条资产选择带入'
          }
        }), _c('van-field', {
          attrs: {
            "label": "调出管理员",
            "required": "",
            "input-align": "right",
            "placeholder": "请输入"
          },
          model: {
            value: _vm.outKeeper,
            callback: function ($$v) {
              _vm.outKeeper = $$v;
            },
            expression: "outKeeper"
          }
        }), _c('van-field', {
          staticClass: "vtextarea",
          attrs: {
            "type": "textarea",
            "label": "说明",
            "rows": "3",
            "autosize": "",
            "maxlength": "200",
            "placeholder": "请输入",
            "input-align": "left",
            "show-word-limit": ""
          },
          model: {
            value: _vm.remark,
            callback: function ($$v) {
              _vm.remark = $$v;
            },
            expression: "remark"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "footer"
        }, [_c('van-button', {
          attrs: {
            "block": "",
            "type": "primary",
            "size": "large"
          },
          on: {
            "click": _vm.onSubmit
          }
        }, [_vm._v("提交")])], 1)];
      },
      proxy: true
    }])
  }), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.productPropertyListShow,
      callback: function ($$v) {
        _vm.productPropertyListShow = $$v;
      },
      expression: "productPropertyListShow"
    }
  }, [_c('van-picker', {
    ref: "productPropertyList",
    attrs: {
      "show-toolbar": "",
      "value-key": "label",
      "columns": _vm.productPropertyList
    },
    on: {
      "confirm": _vm.onProductPropertySelect,
      "cancel": function ($event) {
        _vm.productPropertyListShow = false;
      }
    }
  })], 1), _c('van-popup', {
    style: {
      height: '70vh'
    },
    attrs: {
      "position": "bottom",
      "closeable": ""
    },
    model: {
      value: _vm.productListShow,
      callback: function ($$v) {
        _vm.productListShow = $$v;
      },
      expression: "productListShow"
    }
  }, [_c('div', {
    staticClass: "productListPanel"
  }, [_c('van-search', {
    attrs: {
      "show-action": "",
      "shape": "round",
      "placeholder": "请输入资产名称"
    },
    on: {
      "search": _vm.onProductListSearch
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function () {
        return [_c('div', {
          staticClass: "searchBtn",
          on: {
            "click": _vm.onProductListSearch
          }
        }, [_vm._v("搜索")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.productListSearchKeyword,
      callback: function ($$v) {
        _vm.productListSearchKeyword = $$v;
      },
      expression: "productListSearchKeyword"
    }
  }), _c('div', {
    staticClass: "productListResult"
  }, [_vm._l(_vm.productListGets, function (item, index) {
    return _c('van-cell', {
      key: item.id,
      attrs: {
        "label": item.projectName,
        "clickable": ""
      },
      on: {
        "click": function ($event) {
          return _vm.onProductListSelect(item);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function () {
          return [_c('div', {
            staticClass: "productListResultTitle"
          }, [_c('span', [_vm._v(_vm._s(item.productName))]), _c('span', [_vm._v(_vm._s('库存：' + item.count))])])];
        },
        proxy: true
      }], null, true)
    });
  }), !_vm.productListGets.length ? _c('van-empty', {
    attrs: {
      "description": "无数据"
    }
  }) : _vm._e()], 2), _c('van-pagination', {
    attrs: {
      "total-items": _vm.productListSearchTotal,
      "items-per-page": _vm.productListSearchPageSize
    },
    on: {
      "change": _vm.onProductListSearchPage
    },
    model: {
      value: _vm.productListSearchPageNum,
      callback: function ($$v) {
        _vm.productListSearchPageNum = $$v;
      },
      expression: "productListSearchPageNum"
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };