import _keyBy from "lodash/keyBy";
import _mapValues from "lodash/mapValues";
import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import { mapGetters } from 'vuex';
import moment from 'moment';
import * as assetsApi from '@api/assets';
const defaultProduct = {
  spaceFullCode: null,
  stockId: null,
  productId: null,
  productNo: null,
  productProperty: null,
  productName: null,
  standard: null,
  unit: null,
  productPrice: null,
  count: null,
  applicationNum: 1
};
const productPropertyList = [{
  label: "易耗品",
  value: 1
}, {
  label: "低值品",
  value: 2
}, {
  label: "固定资产",
  value: 3
}];
const productPropertyMap = _mapValues(_keyBy(productPropertyList, i => i.value), j => j.label);
export default {
  name: 'AssetsDispatchApply',
  mixins: [common],
  computed: {
    ...mapGetters('user', ['userInfo', 'projectInfo'])
  },
  data() {
    return {
      inApplicant: '',
      inTime: '',
      inSpaceCode: '',
      inSpaceName: '',
      productList: [{
        ...defaultProduct
      }],
      outProjectId: '',
      outProjectName: '',
      outKeeper: '',
      outSpaceProjectId: '',
      remark: '',
      inSpaceListShow: false,
      inSpaceList: [],
      currentProductIndex: -1,
      productPropertyListShow: false,
      productPropertyList,
      productPropertyMap,
      productListShow: false,
      productListGets: [],
      productListSearchKeyword: '',
      productListSearchPageNum: 1,
      productListSearchPageSize: 10,
      productListSearchTotal: 0
    };
  },
  mounted() {
    this.inApplicant = this.userInfo.userName;
    this.inTime = moment().format('YYYY-MM-DD HH:mm:ss');
  },
  methods: {
    // onInSpaceListShow() {
    //   let { dcProjectId } = this.projectInfo;
    //   assetsApi.getSpaceListByRole(dcProjectId).then(res=>{
    //     if (res.data.data.result) {
    //       this.inSpaceList = res.data.data.result
    //     }
    //   })
    //   this.inSpaceListShow=true;
    // },
    // onInSpaceListSelect(item) {
    //   this.inSpaceCode = item.spaceFullCode;
    //   this.inSpaceName = item.spaceFullName;
    //   this.inSpaceListShow=false;
    // },
    onProductPropertyShow(index) {
      this.productPropertyListShow = true;
      this.currentProductIndex = index;
      this.$nextTick(() => {
        let value = this.productList[index].productProperty;
        let selectIndex = this.productPropertyList.findIndex(i => i.value === value);
        this.$refs.productPropertyList.setIndexes([selectIndex]);
      });
    },
    onProductPropertySelect(item) {
      if (this.productList[this.currentProductIndex].productProperty !== item.value) {
        let product = this.productList[this.currentProductIndex];
        Object.assign(product, defaultProduct);
        product.productProperty = item.value;
      }
      this.productPropertyListShow = false;
    },
    onProductListShow(index) {
      // if (!this.inSpaceCode) {
      //   this.$toast('请选择调入仓库');
      //   return
      // }
      this.currentProductIndex = index;
      let productProperty = this.productList[this.currentProductIndex].productProperty;
      if (!productProperty) {
        this.$toast('请先选择资产属性');
        return;
      }
      this.productListShow = true;
      this.onProductListSearch();
    },
    onProductListSearch() {
      let productProperty = this.productList[this.currentProductIndex].productProperty;
      if (!productProperty) {
        this.$toast('请先选择资产属性');
        return;
      }
      let params = {
        productProperty: productProperty,
        notInProjectId: this.projectInfo.dcProjectId,
        productNameProductNo: this.productListSearchKeyword,
        pageNum: this.productListSearchPageNum,
        pageSize: this.productListSearchPageSize
      };
      assetsApi.getProjectStockProductList(params).then(res => {
        this.productListGets = res.data.data;
        this.productListSearchTotal = res.data.count;
      });
    },
    onProductListSearchPage() {
      this.onProductListSearch();
    },
    onProductListSelect(product) {
      let item = this.productList[this.currentProductIndex];
      item.stockId = product.id;
      item.productNo = product.productNo || '';
      item.productId = product.productId;
      item.productName = product.productName || '';
      item.standard = product.standard || '';
      item.unit = product.unit || '';
      item.count = product.count || 0;
      item.productPrice = product.actPrice || '';
      item.productProperty = product.productProperty;
      item.spaceFullCode = product.spaceFullCode || '';
      this.productListShow = false;
      if (this.currentProductIndex == 0) {
        this.outProjectId = product.projectId;
        this.outProjectName = product.projectName;
        // this.outSpaceCode = product.spaceFullCode;
        // this.outSpaceName= product.spaceName;
        // this.outSpaceProjectId= product.projectId;
      }
    },

    onAddItem() {
      this.productList.push({
        ...defaultProduct
      });
    },
    onDelItem(index) {
      this.$dialog.confirm({
        message: '是否确认删除这条记录？'
      }).then(() => {
        this.productList.splice(index, 1);
      }).catch(() => {
        // on cancel
      });
    },
    onSubmit() {
      // if (!this.inSpaceCode) {
      //   this.$toast('请选择调入仓库')
      //   return
      // }
      if (this.productList.length === 0) {
        this.$toast('请添加物资');
        return;
      } else if (this.productList.length) {
        for (let i = 0; i < this.productList.length; i++) {
          let item = this.productList[i];
          if (!item.productProperty) {
            this.$toast(`请选择第${i + 1}条明细的资产属性`);
            return;
          }
          if (!item.productId) {
            this.$toast(`请选择第${i + 1}条明细的资产名称`);
            return;
          }
        }
      }
      if (!this.outKeeper) {
        this.$toast('请填写调出管理员');
        return;
      }
      this.$dialog.confirm({
        message: '是否确认提交？'
      }).then(() => {
        let {
          dcProjectId
        } = this.projectInfo;
        assetsApi.stockAllocateApply({
          inApplicant: this.inApplicant,
          projectId: dcProjectId,
          inSpaceCode: this.inSpaceCode,
          inSpaceName: this.inSpaceName,
          outProjectId: this.outProjectId,
          outProjectName: this.outProjectName,
          outKeeper: this.outKeeper,
          outSpaceProjectId: this.outSpaceProjectId,
          remark: this.remark,
          productList: this.productList
        }).then(res => {
          this.$router.replace({
            name: 'AssetsDispatchApplySuccess'
          });
        });
      }).catch(() => {
        // on cancel
      });
    }
  }
};